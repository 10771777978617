import config from "src/config";

const LOGGING_ENDPOINT = config.apis.tokenMediatorUrl + "/logs";

const log = (level, message, data = {}) => {
    const logEntry = {
        level,
        message,
        data: {
            sessionID: sessionStorage.getItem("sessionID") ?? "",
            hostedSessionID: sessionStorage.getItem("hostedSessionID") ?? "",
            ...data
        },
        timestamp: new Date().toISOString(),
    };

    try {
        if (navigator.sendBeacon){
            navigator.sendBeacon(
                LOGGING_ENDPOINT,
                JSON.stringify(logEntry)
            );
            return;
        }

        fallbackToXHR(JSON.stringify(logEntry));
    } catch (error) {}
};

const fallbackToXHR = (payload) => {
    try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', LOGGING_ENDPOINT, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(payload);
    } catch (error) {}
};

export const logger = {
    info : (message, data = {}) => log('info', message, data),
    warn : (message, data = {}) => log('warn', message, data),
    error : (message, data = {}) => log('error', message, data),
    debug : (message, data = {}) => log('debug', message, data),
}