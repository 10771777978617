import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Button } from "@mui/material";
import "src/App.css";
import { ThemeSettings } from 'src/theme/workers/types';
import { useEffect } from 'react';

export default function CheckoutError({themeSettings}: { themeSettings : ThemeSettings}) {
    const { t } = useTranslation();

    const buttonColor = {
        backgroundColor: themeSettings.branding.buttonColor || "#1027EA"
    }

    const hasLogo = themeSettings.assets.logo !== "";
    const logo = themeSettings.assets.logo.indexOf('://') > 0 ?
    themeSettings.assets.logo : hasLogo ? require(`src/assets/${themeSettings.assets.logo}`) : "";

    useEffect(() => {
        if(themeSettings.assets.favicon !== ""){
            loadFavicon(themeSettings);
        }
    });

    const loadFavicon = (themeSettings : ThemeSettings) => {
        const link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);
        
         if (themeSettings.assets.favicon.indexOf("://") > 0) {
            (link as HTMLLinkElement).href = themeSettings.assets.favicon;
        } else {
            (link as HTMLLinkElement).href = `/${themeSettings.assets.favicon}-32x32.png`;
        }
    }

    return (<div className="top-margin">
            <Grid container spacing={1} direction="row" justifyContent="center">
                <Grid item xs={12} sm={6} md={4} style={{position: "relative"}}>
                    <div style={{maxHeight: "30px", display: "flex", alignItems: "center", marginBottom: "30px"}}>
                        <div id="backButton" style={{minHeight: "30px", display: "flex", alignItems: "center", float:"left", marginRight: "12px", cursor: "pointer"}} onClick={() => window.history.back()}>
                            <svg width="12" height="12" viewBox="0 0 16 16"><path d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z" fill-rule="evenodd"></path></svg>
                        </div>
                        
                        <div id="logo" style={{float: "left", minWidth: "100px", maxHeight: "30px", fontSize: "14px"}}>
                            { hasLogo && (<img className="branding-logo" width="auto" height="auto" style={{maxHeight: "30px"}} src={logo} alt="brandingLogo"></img>)}
                            { !hasLogo &&(<span>{t("back")}</span>)}
                        </div>

                        <div id="backLabel" style={{float: "left", fontWeight:500, fontSize: "14px"}}>
                            <span>{t("back")}</span>
                        </div>
                    </div>
                </Grid>
                <Grid className="lg-checkout-error" item xs={12} sm={6} md={4}textAlign={"center"}>
                    <div>
                        <span style={{fontSize: "104px", color:"#E7B400"}} className="material-symbols-outlined">Error</span>
                    </div>
                    <div>
                        <h3>{t("checkoutLoadErrorTitle")}</h3>
                    </div>
                    
                    <div>
                        <p>{t("checkoutLoadErrorMessage")}</p>
                    </div>

                    <Button style={buttonColor}
                        onClick={() => window.location.reload()}
                        fullWidth={true}
                        variant="contained">
                        <span>{t("checkoutLoadErrorRetry")}</span>
                    </Button>
                </Grid>

                <Grid className="xs-checkout-error" container xs={12} justifyContent="center" alignItems="center" style={{height: "100%", position:"absolute"}}>
                    <Grid item xs={8} textAlign={"center"}>
                        <div>
                            <span style={{fontSize: "104px", color:"#E7B400"}} className="material-symbols-outlined">Error</span>
                        </div>
                        <div>
                            <h3>{t("checkoutLoadErrorTitle")}</h3>
                        </div>
                        
                        <div>
                            <p>{t("checkoutLoadErrorMessage")}</p>
                        </div>
                    </Grid>
                    <Grid container xs={10} direction="row" style={{position:'absolute', bottom:'10px'}}>
                            <Button style={buttonColor}
                                onClick={() => window.location.reload()}
                                fullWidth={true}
                                variant="contained">
                                <span>{t("checkoutLoadErrorRetry")}</span>
                            </Button>    
                    </Grid>
                </Grid>
            </Grid>
        </div>
);
}
